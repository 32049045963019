<template>
	<div>
		<section class="header-bg" style="min-height: 255px;">
			
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">BROWSE
						JOURNALS</strong></p>
			</div>
			<div class="layui-fluid">
				<div class="layui-row layui-col-space30" style="margin-top: 40px;">
					<div class="layui-col-md3">
						<div>
							<h3><strong>JOURNALS A-Z</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<ul class="letter" style="padding: 20px;display: flex;flex-wrap: wrap;">
									<li v-for="(item,index) of journalAofZ" :key="index"
										:class="journalAofZAri==index?'activity':''" @click="aofzCli(item,index)">{{item}}
									</li>
								</ul>
							</div>
						</div>
						<div style="margin-top: 20px;" class="layui-hide-xs">
							<h3><strong>PUBLICATION</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<form class="layui-form" action="" lay-filter="publisherP">
									<!--								publiction多选框-->
									<el-checkbox-group v-model="publisherP" @change="checkboxCli" text-color="red">
										<ul class="search sourceTitle" style="padding: 20px;">
											<li v-for="(item,index) of publication" :key="index">
												<el-checkbox :label="item.a">{{item.a}}({{item.b}},{{item.c}})</el-checkbox>
											</li>
										</ul>
									</el-checkbox-group>
								</form>
							</div>
						</div>
						<div style="margin-top: 20px;line-height: 30px;" class="layui-hide-xs">
							<h3><strong>YEAR OF PUBLICATION</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<form class="layui-form" action="" lay-filter="publicationYear">
									<el-checkbox-group v-model="publicationYear" @change="checkboxCli" text-color="red">
										<ul class="search sourceTitle" style="padding: 20px;">
											<li v-for="(item,index) of yearPublication" :key="index">
												<el-checkbox :label="item.a">{{item.a}}({{item.b}},{{item.c}})</el-checkbox>
											</li>
										</ul>
									</el-checkbox-group>
								</form>
							</div>
						</div>
						<div style="margin-top: 20px;line-height: 30px;" class="layui-hide-xs">
							<h3><strong>DOCUMENT TYPES</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<el-checkbox-group v-model="documentType" @change="checkboxCli" text-color="red">
									<ul class="search sourceTitle" style="padding: 20px;">
										<li v-for="(item,index) of docType" :key="index">
											<el-checkbox :label="item.a">{{item.a}}({{item.b}},{{item.c}})</el-checkbox>
										</li>
									</ul>
								</el-checkbox-group>
							</div>
						</div>
					</div>
					<div class="layui-col-md9">
						<div>
							<h3><strong>JOURNALS</strong>
		
								<span v-if="authorFullNames!=null &&authorFullNames!=''">
		
									<strong style="margin-left: 40px;">Author Full Names:</strong>
									<strong style="color: #1da6b8;" id="authorFullNames">${authorFullNames}</strong>
								</span>
								<p class="right">Results: <span class="currPage">1</span> - 10 of <span
										class="countNum">{{dataCount}}</span><span class="loading" v-if="loadingShow"><img
											src="../../assets/images/loading.gif" style="width: 45px;" /></span>
		
								</p>
							</h3>
		
							<div class="layui-form-item" style="margin-top: 10px">
								<div class="layui-input-group" style="width: 100%;">
		
									<input type="text" placeholder="serach" id="matchSerachText" class="layui-input"
										v-model="paramMap.againMatchSourceTitle">
									<div id="matchSerach" class="layui-input-split layui-input-suffix" @click="pageCli">
										<i class="layui-icon layui-icon-search" style="color: white"></i>
									</div>
								</div>
							</div>
		
		
							<div class="content">
								<div class="layui-panel" style="margin-top: 20px;padding: 20px;"
									v-for="(item,index) of journalList" :key="index">
									<p class="two float-hand-color" style="margin-top: 20px;font-size: 16px;height: 36px;"
										data-id="16" @click="enterCli(item.sourceTitle)">
										<strong>{{item.sourceTitle}}</strong>
									</p>
								</div>
							</div>
							<div id="demo3" style="text-align: center;margin-top: 40px;">
								<!--							分页-->
								<el-pagination background layout="prev, pager, next" :total="dataCount"
									v-model:current-page="paramMap.page" @size-change="" @current-change="pageCli" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	
</template>

<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				dataCount: 0,
				currentPage: 1,
				journalList: [],
				paramMap: {
					againMatchSourceTitle: "",
					articleTitleAZ: "A",
					authorFullNames: "",
					documentType: "{}",
					page: 1,
					publicationYear: "{}",
					publisherP: "{}"
				},
				journalAofZAri: 0,
				journalAofZ: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R",
					"S", "T", "U", "V", "W", "X", "Y", "Z"
				],
				publication: [{
					a: "WILEY",
					b: "126",
					c: "369"
				}, {
					a: "ELSEVIER",
					b: "110",
					c: "288"
				}, {
					a: "ELSEVIER SCI LTD",
					b: "95",
					c: "904"
				}, {
					a: "PERGAMON-ELSEVIER SCIENCE LTD",
					b: "94",
					c: "090"
				}, {
					a: "ELSEVIER SCIENCE BV",
					b: "85",
					c: "772"
				}, {
					a: "SPRINGER",
					b: "85",
					c: "706"
				}, {
					a: "AMER CHEMICAL SOC",
					b: "74",
					c: "579"
				}, {
					a: "MDPI",
					b: "73",
					c: "382"
				}, {
					a: "IEEE-INST ELECTRICAL ELECTRONICS ENGINEERS INC",
					b: "70",
					c: "286"
				}, {
					a: "ROYAL SOC CHEMISTRY",
					b: "41",
					c: "706"
				}],
				publisherP: [],
				yearPublication: [{
					a: "2022",
					b: "40",
					c: "963"
				}, {
					a: "2021",
					b: "54",
					c: "206"
				}, {
					a: "2020",
					b: "260",
					c: "285"
				}, {
					a: "2019",
					b: "344",
					c: "537"
				}, {
					a: "2018",
					b: "336",
					c: "425"
				}, {
					a: "2017",
					b: "320",
					c: "777"
				}, {
					a: "2016",
					b: "290",
					c: "507"
				}],
				publicationYear: [],
				docType: [{
					a: "Article",
					b: "143",
					c: "7672"
				}, {
					a: "Review",
					b: "18",
					c: "3201"
				}, {
					a: "Article; Proceedings Paper",
					b: "2",
					c: "3969"
				}],
				documentType: [],
				loadingShow: true,
			};
		},
		methods: {
			queryJournalCount() {
				this.loadingShow = true;
				$http.post("countJournalUniqueSourceTitle", this.paramMap, false).then(res => {
					this.dataCount = res.data.count
					this.loadingShow = false;
				}).catch(err => {
					console.log("123123", err);
				})
			},
			queryJournalList() {


				$http.post("getJournalListUniqueSourceTitle", this.paramMap, true).then(res => {
					this.journalList = res.data.journalList;

				}).catch(err => {
					console.log(err);
				});
			},
			pageCli() {
				this.queryJournalList();
				this.queryJournalCount();
			},
			checkboxCli() {
				this.paramMap.publisherP = "{"
				this.publisherP.forEach((res, index) => {
					if (index == this.publisherP.length - 1) {
						this.paramMap.publisherP += '"' + res + '"' + ":" + '"on"';
					} else {
						this.paramMap.publisherP += '"' + res + '"' + ":" + '"on",';
					}
				})
				this.paramMap.publisherP += "}"

				this.paramMap.publicationYear = "{"
				this.publicationYear.forEach((res, index) => {
					if (index == this.publicationYear.length - 1) {
						this.paramMap.publicationYear += '"' + res + '"' + ":" + '"on"';
					} else {
						this.paramMap.publicationYear += '"' + res + '"' + ":" + '"on",';
					}
				})
				this.paramMap.publicationYear += "}"


				this.paramMap.documentType = "{"
				this.documentType.forEach((res, index) => {
					if (index == this.documentType.length - 1) {
						this.paramMap.documentType += '"' + res + '"' + ":" + '"on"';
					} else {
						this.paramMap.documentType += '"' + res + '"' + ":" + '"on",';
					}
				})
				this.paramMap.documentType += "}"


				this.queryJournalList()
				this.queryJournalCount();
			},
			aofzCli(item, index) {
				this.journalAofZAri = index;
				this.paramMap.articleTitleAZ = item;
				this.queryJournalList();
				this.queryJournalCount();
			},
			enterCli(item) {
				this.$router.push({
					path: "/journalSource",
					query: {
						name: item
					}
				});
			},
			
					
		},

		created() {
			this.queryJournalList();
			this.queryJournalCount();
		},
		mounted() {

		},

	};
</script>
<style lang="scss" scoped>
	
	
	::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
		background-color: #16baaa;
		color:#fff;
	}
	::v-deep .el-pager li:hover{
		color: #16baaa;
	}
	::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover{
		color: #16baaa;
	}
	
	::v-deep .el-checkbox__inner:hover{
		border-color: #16baaa;
	}
	
	::v-deep .el-checkbox{
		height: 35px;
	}
	
	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
		background-color: #16baaa;
		border-color: #16baaa;
	}
	
	::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
		color: #16baaa;
	}
</style>